@use '../utils/' as *;

.form-flow-wrapper {
  .header-tabs {
    .zinq-tabs-nav {
      padding: 0 24px;
      margin: 0;
      border-bottom: 1px solid var(--border-primary);
      height: 64px;
    }
  }
  .editor-wrapper {
    height: 100vh;
    background-color: var(--background);
    .left-tabs {
      .zinq-tabs-nav {
        padding: 0;
        .zinq-tabs-nav-wrap {
          .zinq-tabs-nav-list {
            // .zinq-tabs-tab {
            //   &.zinq-tabs-tab-active {
            //     border-radius: 16px;
            //     border: 1px solid var(--neutrals3);
            //     background: var(--neutrals1);
            //     box-shadow:
            //       25px 48px 15px 0px rgba(0, 0, 0, 0),
            //       16px 31px 14px 0px rgba(0, 0, 0, 0.01),
            //       9px 17px 12px 0px rgba(0, 0, 0, 0.05),
            //       4px 8px 9px 0px rgba(0, 0, 0, 0.09),
            //       1px 2px 5px 0px rgba(0, 0, 0, 0.1),
            //       3px 3px 1.9px 0px rgba(255, 255, 255, 0.88) inset,
            //       -3px -3px 5px 0px rgba(0, 0, 0, 0.18) inset;
            //     .zinq-tabs-tab-btn {
            //       color: var(--neutrals10);
            //     }
            //   }
            //   border-radius: 16px;
            //   background: var(--neutrals1, #f3f5f6);
            //   .zinq-tabs-tab-btn {
            //     color: var(--neutrals6);
            //   }
            // }
          }
        }
      }
      .zinq-tabs-content-holder {
        border: 0;
        padding: 0 24px 24px;
        overflow-y: auto;
        height: calc(100vh - 54px);
        max-width: $containerMd;
        margin: 0 auto;
        .zinq-tabs-content {
          overflow-y: auto;
          height: calc(100vh - 105px);
          border: 1px solid var(--neutrals3);
          padding: 24px;
          border-radius: 16px;
          .zinq-tabs-tabpane {
            padding: 0;
            height: 100%;
          }
        }
      }
    }
  }
  .form-flow-left {
    margin: 0;
    width: 400px;
    .zinq-input {
      padding: 0;
      border: 0;
      width: auto;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.draggable-form-list-wrapper {
  .draggable-form-list {
    .zinq-list-item {
      border: 0;
      .custom-list-renderer {
        display: flex;
        justify-content: space-between;
        .form-item-overview {
          display: flex;
          align-items: center;
          gap: 8px;
          background: var(--surface-secondary);
          border-radius: 12px;
          padding: 16px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          position: relative;
          cursor: pointer;
          &:hover {
            box-shadow:
              0px 17px 7px 0px rgba(0, 0, 0, 0.01),
              0px 2px 6px 0px rgba(0, 0, 0, 0.08);
            border-color: var(--border-secondary);
            .card-controls {
              opacity: 1;
            }
          }
          .card-controls {
            opacity: 0;
            position: absolute;
            top: 16px;
            right: 16px;
            transition: all 0.2s ease-in-out;
          }
        }
        .form-item-card {
          .card-action-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            .copy-icon {
              margin-bottom: 0;
            }
            .required-setting {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
            }
          }
          .option-list {
            padding: 8px;
            border: 1px solid var(--neutrals1);
            border-radius: 6px;
          }

          // .zinq-row {
          //   .zinq-col {
          //     label {
          //       font-size: 14px;
          //       color: var(--neutrals8);
          //       font-weight: 500;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.conversational-view-wrapper {
  height: 100vh;
  overflow-y: auto;
  display: table;
  width: 100%;
  .form-response {
    height: 100vh;
    padding: 16px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    display: table-cell;
    vertical-align: middle;
    width: 1%;
    .zinq-form {
      .zinq-input-outlined {
        border-bottom: 1px solid var(--neutrals5);
        box-shadow: none;
      }
      .group-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 24px;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.2);
        margin-bottom: 32px;
        backdrop-filter: blur(20px);
        &.light {
          background: rgba(255, 255, 255, 0.2);
        }
        @include respond-to($screen991) {
          grid-template-columns: repeat(1, 1fr);
        }
        .markdown-wrapper {
          @include headingH2();
          @include respond-to($screen991) {
            @include bodyM();
          }
        }
      }
    }
  }
}

.form-preview-mode {
  border-radius: 16px;
  padding: 24px;
  height: calc(100vh - 146px);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  .classic-form-preview {
    max-width: 676px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    .classic-form-renderer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .conversational-form-preview {
    max-width: 916px;
    width: 100%;
    height: calc(100vh - 195px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .conversational-form-renderer {
      width: 100%;
    }
  }
}

.zinq-form-builder-config {
  .background-wrapper {
    box-shadow:
      0px 39px 23px 0px rgba(0, 0, 0, 0.03),
      0px 17px 17px 0px rgba(0, 0, 0, 0.04),
      0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: var(--surface-primary);
    border-radius: 20px 20px 0px 0px;
    padding: 8px;
    position: relative;
    .scroll-wrapper {
      height: calc(100vh - 148px);
      overflow-y: auto;
      padding: 8px 8px 90px 8px;
      .form-buttons {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .form-save-buttons {
        position: absolute;
        bottom: -28px;
        left: 50%;
        transform: translate(-50%, -50%);
        border-top: 1px solid var(--border-primary);
        padding: 16px;
        background: var(--surface-primary);
        width: 100%;
      }
      #add-item-container {
        .zinq-dropdown {
          width: 100%;
          max-width: 300px;
          .zinq-dropdown-menu {
            padding: 16px;
            gap: 6px;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            box-shadow:
              0px 5px 11px 0px rgba(0, 0, 0, 0.1),
              0px 20px 20px 0px rgba(0, 0, 0, 0.09),
              0px 44px 27px 0px rgba(0, 0, 0, 0.05),
              0px 79px 31px 0px rgba(0, 0, 0, 0.01),
              0px 123px 34px 0px rgba(0, 0, 0, 0),
              0px 0px 0px 1px rgba(23, 27, 28, 0.1);
            border: 0;
            background: var(--surface-primary);
            margin-bottom: 8px;
            .zinq-dropdown-menu-item {
              padding: 8px 12px;
              border-radius: 12px;
              font-weight: 500;
              background: var(--surface-secondary);
              border: 1px solid transparent;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: var(--surface-tertiary);
              }
              &:first-child {
                background-color: transparent;
                pointer-events: none;
                padding: 0;
                color: var(--content-secondary);
              }
              &.zinq-dropdown-menu-item-disabled {
                .text-description {
                  color: #00000040;
                }
              }
            }
          }
        }
      }
      .form-settings-wrapper {
        .section-divider {
          border-color: var(--border-primary);
          margin: 4px 0;
        }
        .checkbox-description-box {
          .checkbox-box {
            padding: 16px;
            border-radius: 16px;
            border: 1px solid var(--border-primary);
            background: var(--surface-secondary);
            min-height: 100%;
            .zinq-checkbox-wrapper {
              @include bodyBase(500);
              color: var(--content-primary);
            }
          }
        }
      }
      .zinq-form-item .zinq-form-item-label > label {
        font-weight: 500;
      }
    }
  }
  .draggable-form-list {
    .form-item-card {
      background: var(--surface-secondary);
      padding: 16px;
      border-radius: 12px;
    }
  }
}

.form-selected-item {
  margin-bottom: 30px;
  .selected-item-divider {
    border-color: var(--border-primary);
    margin: 0;
  }
  .card-fields {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border: 1px solid var(--neutrals1);
    border-radius: 8px;
  }
  .options-list-collapse {
    .zinq-collapse-item {
      .zinq-collapse-header {
        padding: 0;
      }
      .zinq-collapse-content-box,
      .zinq-collapse-content-active {
        padding-inline: 0;
        .zinq-list-item {
          padding-block: 6px;
        }
      }
    }
  }
  .file-type-checkbox-group {
    padding: 10px 12px;
    background: var(--surface-secondary);
    border-radius: 8px;
    border: 1px solid var(--border-primary);
  }
  .input-nested-fields {
    .row-wrapper {
      @include respond-to($screen1080) {
        flex-wrap: wrap;
      }
      .zinq-tag {
        &.zinq-tag-warning {
          border-radius: 16px;
          height: 40px;
          border: 0;
          background: var(--surface-secondary);
        }
      }
    }
  }
}

.page-layout-option-wrapper {
  .box {
    border-radius: 12px;
    border: 2px solid var(--border-primary);
    padding: 6px;
    height: 90px;
    width: 132px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &.active,
    &:hover {
      border-color: var(--surface-inverse-tertiary);
    }
    .page-filled {
      .mock-bar {
        height: 8px;
        width: 76px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 60px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    .page-left {
      .mock-bar {
        height: 8px;
        width: 56px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 44px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    .page-top-center {
      .mock-bar {
        height: 8px;
        width: 68px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 52px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    &.box-bg {
      background: url('../../assets/images/page-layout.png');
    }
  }
}

.premium-logo {
  filter: drop-shadow(0px 2px 3.4px rgba(0, 0, 0, 0.1));
}

.theme-list-wrapper {
  .card {
    height: 92px;
    width: 132px;
    border-radius: 12px;
    border: 1px solid var(--border-primary);
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 12px;
    background-color: #eaeafe;
    &:hover,
    &.active {
      box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 2px #fff,
        0px 0px 0px 4px var(--content-tertiary);
    }
  }
}

.theme-variations-wrapper {
  .card {
    height: 92px;
    width: 132px;
    border-radius: 12px;
    border: 1px solid var(--border-primary);
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 12px;
    background: linear-gradient(140deg, #efe5cb 1.27%, #cbefe9 70.38%);
    display: flex;
    justify-content: center;
    align-items: center;
    .mock-bar {
      border-radius: 8px;
      background: var(--content-primary);
      width: 96px;
      height: 8px;
    }
    .mock-sub-bar {
      border-radius: 8px;
      background: var(--content-primary);
      width: 74px;
      height: 8px;
    }
    .mock-button {
      border-radius: 4px;
      background: var(--content-primary);
      min-width: 62px;
      height: 16px;
      border: 4px solid var(--content-primary);
    }

    &:hover,
    &.active {
      box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 2px #fff,
        0px 0px 0px 4px var(--content-tertiary);
    }
  }
}

.radio-group-box {
  .zinq-radio-wrapper {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--border-primary);
    background: var(--surface-secondary);
    padding: 12px;
    min-height: 100%;
    @include bodyBase(500);
    color: var(--content-primary);
    display: flex;
    align-items: center;
    &.zinq-radio-wrapper-disabled {
      color: var(--content-quarternary);
    }
  }
}

.checkbox-group-box {
  .zinq-checkbox-wrapper {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--border-primary);
    background: var(--surface-secondary);
    padding: 16px;
    @include bodyBase(500);
  }
}

.welcome-page-wrapper {
  .page-top-center {
    .page-image {
      max-width: 480px;
      object-fit: cover;
      max-height: 360px;
      border-radius: 12px;
    }
    .page-title {
      font-size: 56px;
      font-weight: 400;
      line-height: 68px;
      text-align: center;
      @include respond-to($screen991) {
        @include headingH1();
      }
    }
    .page-body {
      max-width: 800px;
      text-align: center;
      font-size: 18px;
      @include respond-to($screen991) {
        @include bodyBase();
      }
    }
  }
  .page-left {
    .page-image {
      max-width: 480px;
      width: 100%;
      object-fit: cover;
      max-height: 360px;
      border-radius: 12px;
    }
    .page-left-footer {
      @include respond-to($screen991) {
        align-items: center;
      }
      .page-title {
        font-size: 56px;
        font-weight: 400;
        line-height: 68px;
        @include respond-to($screen991) {
          text-align: center;
          @include headingH1();
        }
      }
      .page-body {
        max-width: 640px;
        font-size: 20px;
        @include respond-to($screen991) {
          text-align: center;
          @include bodyBase();
        }
      }
    }
  }
}

.form-branding-page {
  .text-response {
    font-size: 42px;
    font-weight: 400;
  }
  .branding-box {
    padding: 24px;
    backdrop-filter: blur(16px);
    border: 1px rgba(229, 232, 233, 0.2);
    border-radius: 16px;
    margin-bottom: 100px;
    max-width: 800px;
    min-width: 800px;
    margin-inline: auto;
    @include respond-to($screen991) {
      max-width: 343px;
      min-width: 343px;
    }
    .text-header {
      margin-top: 0;
      text-align: center;
    }

    &.dark {
      background: rgba(245, 246, 247, 0.1);
      .text-header {
        color: var(--content-inverse-primary);
      }
    }

    &.light {
      background: rgba(26, 26, 26, 0.1);

      .text-header {
        color: var(--content-primary);
      }
    }
  }
}

.markdown-wrapper {
  @include headingH1();
  @include respond-to($screen991) {
    @include bodyL();
  }
  p {
    margin-bottom: 0;
  }
}
