.font-primary {
  font-family: 'Inter', sans-serif;
}

.font-secondary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  &.medium {
    font-weight: 600;
  }
}
