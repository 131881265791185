@use '../utils/mixin' as *;

// Title class
h1 {
  &.zinq-typography {
    // font-size: 32px;
    line-height: 38px;
    margin: 0;
    &.normal {
      font-weight: 400;
    }
  }
}

h2 {
  &.zinq-typography {
    // font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
}

h3 {
  &.zinq-typography {
    // font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
}

h4 {
  &.zinq-typography {
    // font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
}

h5 {
  &.zinq-typography {
    // font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
}

// Body class
.text-lg {
  @include bodyL;
}

.text-m {
  @include bodyM;
}

.text-base {
  @include bodyBase;
}

.text-sm {
  @include bodyS;
}

.text-meta {
  @include bodyMeta;
}

.text-micro {
  @include bodyMicro;
}
