@use '../utils' as *;

.zinq-btn {
  transition: all 0.5s ease;
  &.zinq-btn-primary {
    box-shadow:
      0px 17px 7px 0px rgba(0, 0, 0, 0.01),
      0px 10px 6px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(32, 32, 32, 0.1),
      0px 1px 0px 0px rgba(32, 32, 32, 0.1);
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-inverse-primary);
      color: var(--neutrals);
    }
  }
  &.zinq-btn-default {
    box-shadow:
      0px 1px 2px 0px rgba(23, 27, 28, 0.1),
      0px 0px 0px 1px rgba(23, 27, 28, 0.1);
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-primary);
      color: var(--content-primary);
    }
  }
  &.zinq-btn-text {
    box-shadow:
      0px 1px 2px 0px rgba(23, 27, 28, 0.1),
      0px 0px 0px 1px rgba(23, 27, 28, 0.1);
    background-color: var(--surface-secondary);
    &:hover {
      background-color: var(--surface-tertiary);
    }
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-secondary);
      color: var(--content-primary);
    }
  }
  &.shadow-hidden {
    box-shadow: none;
  }
  &.bg-surface-tertiary {
    background-color: var(--surface-tertiary);
  }
}

.brand-btn-primary {
  border-radius: 160px;
  background: #120b3b;
  padding: 16px 20px;
  box-shadow:
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset,
    0px 0.717px 0px 0px #fff inset,
    0px 13px 8px 0px rgba(0, 0, 0, 0.05),
    0px 7.167px 6px 0px rgba(0, 0, 0, 0.08),
    0px 2.867px 4px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0.8px rgba(32, 32, 32, 0.1),
    0px 0.717px 0px 0px rgba(32, 32, 32, 0.1),
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset;
  color: var(--content-inverse-primary);
  font-weight: 600;
  font-family: var(--font-secondary);
  font-size: 16px;
  border: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
}

.brand-btn-secondary {
  border-radius: 160px;
  background: linear-gradient(180deg, #f9f8f6 0%, #f9cfff 100%);
  padding: 16px 20px;
  box-shadow:
    0px 13px 8px 0px rgba(0, 0, 0, 0.05),
    0px 7.167px 6px 0px rgba(0, 0, 0, 0.08),
    0px 2.867px 4px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0.8px rgba(32, 32, 32, 0.1),
    0px 0.717px 0px 0px rgba(32, 32, 32, 0.1),
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset,
    0px 0.717px 0px 0px #fff inset;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 600;
  border: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
  .btn-text {
    background: linear-gradient(180deg, #bd7fc7 0%, #7f388a 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
