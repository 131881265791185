@use '../utils' as *;

.screen-layout {
  .zinq-layout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f5f6;
    height: 100vh;
    overflow-y: auto;
  }
}

.form-layout {
  .zinq-layout-content {
    background: var(--neutrals);
    height: 100vh;
    overflow-y: hidden;
  }
}

// css to take element middle of the parent element
.content-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.form-view-layout {
  .zinq-layout-content {
    background: var(--neutrals);
    height: 100vh;
    overflow-y: auto;
  }
}
