@use '../utils/' as *;

.feedback-card {
  border-radius: 20px;
  border: 0;
  padding: 16px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all ease 0.3s;
  border-radius: 20.8px;
  min-height: 214px;
  &:hover {
    box-shadow:
      5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
      2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
      1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
  }
  .new-response-tag {
    border-radius: 16px;
    border: 0;
    padding: 2px 8px;
  }
  .draft-tag {
    border-radius: 26px;
    color: var(--content-secondary);
    border: 0;
    padding: 4px 8px;
    background: var(--surface-tertiary);
  }
}

.create-card {
  padding: 16px;
  height: 100%;
  cursor: pointer;
  border-radius: 20.8px;
  border: 1px solid var(--border-secondary);
  background: var(--surface-primary);
  min-height: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;

  .text-description {
    margin-bottom: 0px;
    @include bodyS;
    &.mb-32 {
      margin-bottom: 32px;
    }
  }

  .feature-card {
    border-radius: 12px;
    padding: 8px 12px;
    height: 100%;
    cursor: pointer;
    &.active {
      &.orange {
        box-shadow:
          0px 0px 0px 2px #fff,
          0px 0px 0px 4px var(--orange),
          0px 4px 16px 0px var(--orange);
      }
      &.purple {
        box-shadow:
          0px 0px 0px 2px #fff,
          0px 0px 0px 4px var(--purple),
          0px 4px 16px 0px var(--purple);
      }
      &.rose {
        box-shadow:
          0px 0px 0px 2px #fff,
          0px 0px 0px 4px var(--rose),
          0px 4px 16px 0px var(--rose);
      }
    }
  }
  .example-card {
    background: var(--neutrals);
    padding: 16px;
    border-radius: 20px;
    box-shadow:
      0px 4px 4px 0px rgba(0, 0, 0, 0.08),
      0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(32, 32, 32, 0.06);
    .example-card-title {
      font-size: 18px;
      color: var(--content-secondary);
      margin-bottom: 8px;
    }
    .example-card-description {
      color: var(--content-tertiary);
      @include bodyS();
      margin-bottom: 24px;
    }
    .use-this-btn {
      box-shadow:
        0px 1px 2px 0px rgba(23, 27, 28, 0.1),
        0px 0px 0px 1px rgba(23, 27, 28, 0.1);
      background: var(--surface-secondary);
      color: var(--content-primary);
      border-radius: 50px;
      border: 0;
      font-weight: 500;
      height: 32px;
      @include bodyS(500);
    }
  }
  .max-divider {
    max-width: 800px;
    width: 100%;
  }
  .prompt-form {
    max-width: 900px;
    .information-wrapper {
      .input-prompt {
        padding: 12px 32px 12px 16px;
      }
      .float-microphone {
        position: absolute;
        top: 12px;
        right: 16px;
        cursor: pointer;
      }
    }
  }
  .example-list {
    background-color: var(--surface-tertiary);
    padding: 8px 0;
    border-radius: 16px;
    .card-box {
      padding: 12px;
      border-radius: 13px;
      background: var(--neutrals);
      .card-heading {
        @include bodyMeta(500);
        color: var(--content-quarternary);
        margin-bottom: 0;
      }
    }
  }
}
