@use '../utils/breakpoint' as *;

// alignments
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.w-max-content {
  width: max-content;
}

.w-auto {
  width: auto;
}

.pointer-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

// borders
.border-none {
  border: 0;
}

// border radius
.border-sm {
  border-radius: 4px;
}
.border-md {
  border-radius: 8px;
}
.border-lg {
  border-radius: 12px;
}
.border-xl {
  border-radius: 14px;
}
.border-xxl {
  border-radius: 16px;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

// display
.m-hide {
  display: block;

  @include respond-to($screen767) {
    display: none;
  }
}

.d-hide {
  display: none;

  @include respond-to($screen767) {
    display: block;
  }
}

// display
.d-none {
  display: none;
}

.text-medium {
  font-weight: 500;
}

// position
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

// overflow
.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

// max-width

.max-w-600 {
  max-width: 600px;
}

.max-w-800 {
  max-width: 800px;
}

// max-height
.max-h-screen {
  max-height: 100vh;
}

// margin
.mx-auto {
  margin: 0 auto;
}

// font weights
.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.word-no-break {
  word-break: keep-all;
}

// opacity
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-4 {
  opacity: 0.4;
}

// shadow

.shadow-none {
  box-shadow: none;
}
