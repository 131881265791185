@use '../utils' as *;

.app-header {
  height: 64px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  border-bottom: 1px solid var(--border-primary);
}
