:root {
  --neutrals: #ffffff;
  --neutrals1: #f3f5f6;
  --neutrals2: #e8ebec;
  --neutrals3: #dce1e3;
  --neutrals4: #d1d7da;
  --neutrals5: #c5ced1;
  --neutrals6: #98a0a3;
  --neutrals7: #787e80;
  --neutrals8: #595d5f;
  --neutrals9: #3c3e3f;
  --neutrals10: #212222;
  --neutrals11: #000000;
  --white: #ffffff;
  --primary: #0071e3;
  --orange50: #fff7ed;
  --purple50: #f5f3ff;
  --rose50: #fff1f2;
  --orange: #f97316;
  --purple: #7c3aed;
  --rose: #f43f5e;
  --red: #ff0000;
  --red50: #ef4444;
  --green: #007558;
  --dark-blue: #042f45;
  --yellow40: #fbbf24;
  --content-primary: #343b3f;
  --content-secondary: #586165;

  // new theme color codes

  // Primitives
  --brand-primary-main: #9642e9;
  --brand-primary-shade-1: #7e1ae0;
  --brand-primary-shade-2: #6415b2;
  --brand-primary-shade-3: #4a0f84;
  --brand-primary-tint-1: #b071ee;
  --brand-primary-tint-2: #ca9ff4;
  --brand-primary-tint-3: #e3cdf9;
  --neutral-00: #ffffff;
  --neutral-01: #f3f5f6;
  --neutral-02: #f5f6f7;
  --neutral-03: #e5e8e9;
  --neutral-04: #ccd0d2;
  --neutral-05: #a9aeb1;
  --neutral-06: #767f83;
  --neutral-07: #586165;
  --neutral-08: #343b3f;
  --neutral-09: #21282b;
  --neutral-10: #171b1c;
  --system-blue-dark: #2e93ff;
  --system-blue-light: #d2e5f9;
  --system-green-dark: #00994a;
  --system-green-light: #b7f0ce;
  --system-red-dark: #e50013;
  --system-red-light: #f9d2d9;
  --system-yellow-dark: #c2a200;
  --system-yellow-light: #faefb8;
  --premium-yellow: #f59e0b;

  --background: var(--neutral-01);
  --link: var(--system-blue-dark);
  --caution-on-surface: var(--system-yellow-dark);
  --caution-surface: var(--system-yellow-light);
  --danger-on-surface: var(--system-red-dark);
  --danger-surface: var(--system-red-light);
  --success-on-surface: var(--system-green-dark);
  --success-surface: var(--system-green-light);
  --accent-primary: var(--brand-primary-shade-3);
  --border-primary: var(--neutral-03);
  --border-secondary: var(--neutral-04);
  --content-inverse-primary: var(--neutral-01);
  --content-inverse-quarternary: var(--neutral-04);
  --content-inverse-secondary: var(--neutral-02);
  --content-inverse-tertiary: var(--neutral-03);
  --content-primary: var(--neutral-08);
  --content-quarternary: var(--neutral-05);
  --content-secondary: var(--neutral-07);
  --content-tertiary: var(--neutral-06);
  --surface-inverse-tertiary: var(--neutral-07);
  --surface-inverse-primary: var(--neutral-09);
  --surface-inverse-secondary: var(--neutral-08);
  --surface-primary: var(--neutral-00);
  --surface-secondary: var(--neutral-02);
  --surface-tertiary: var(--neutral-03);

  // fonts
  --font-secondary: 'Plus Jakarta Sans', 'sans-serif';
}

// content primary
$contentPrimary: var(--content-primary);
$contentSecondary: var(--content-secondary);

// variables
$neutrals: var(--neutrals);
$neutrals1: var(--neutrals1);
$neutrals2: var(--neutrals2);
$neutrals3: var(--neutrals3);
$neutrals4: var(--neutrals4);
$neutrals5: var(--neutrals5);
$neutrals6: var(--neutrals6);
$neutrals7: var(--neutrals7);
$neutrals8: var(--neutrals8);
$neutrals9: var(--neutrals9);
$neutrals10: var(--neutrals10);
$neutrals11: var(--neutrals11);
$orange50: var(--orange50);
$purple50: var(--purple50);
$rose50: var(--rose50);
$rose50: var(--rose50);

$orange: var(--orange);
$purple: var(--purple);
$rose: var(--rose);
$red50: var(--red50);

$white: var(--white);
$primary: var(--primary);

$green: var(--green);
$darkBlue: var(--dark-blue);
$yellow40: var(--yellow40);

$transparent: transparent;

// container
$container: 1000px;
$containerMd: 1200px;
